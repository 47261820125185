import _ from "lodash";
import AceEditor from "react-ace";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { flaskOutline, listOutline } from "ionicons/icons";
import { getEditorTheme } from "../../../../utils/common/helper";
import { QuestionAttrs } from "@com.xcodeclazz/monolithic-common";
import EditorState from "../../../../utils/algorithm/editor-state";
import { IonButton, IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import { updateCurrentCodingQuestionState } from "../../../../redux/reducers/questionState";

const CodingComponent: React.FC<{
  currentQuestion: QuestionAttrs | undefined,
  onTestClick: () => void;
}> = ({ currentQuestion, onTestClick }) => {
  const dispatch = useDispatch();
  const editorState = new EditorState();

  const [fontSize] = useState<number>(18);
  const [showGutter, setShowGutter] = useState<boolean>(false);
  const isNotAllow = currentQuestion?.attempAllow! === 0;

  const onChangeHandler = (src: string) => dispatch(updateCurrentCodingQuestionState({ lang: currentQuestion?.language, src }));

  return (
    <>
      <IonGrid>
        <IonRow>
            <IonCol className="hidden md:block" size="3" sizeSm="2" sizeLg="1">
                <IonButton title="Show/hide line numbers" fill="clear" onClick={e => setShowGutter(!showGutter)}>
                  <IonIcon icon={listOutline} size="small" />
                </IonButton>
            </IonCol>
            <IonCol className="hidden md:block" size="3" sizeSm="2" sizeLg="1" onClick={onTestClick}>
                <IonButton title="Test code" fill="clear">
                  <IonIcon icon={flaskOutline} size="small" />
                </IonButton>
            </IonCol>
        </IonRow>
      </IonGrid>
      <AceEditor
        name={`ID-${Math.random()}`}
        mode={editorState.getMode(currentQuestion!.language)}
        width="100"
        theme={getEditorTheme()}
        fontSize={fontSize}
        highlightActiveLine={true}
        showPrintMargin={true}
        readOnly={isNotAllow}
        showGutter={showGutter}
        wrapEnabled={true}
        editorProps={{ $blockScrolling: true }}
        style={{ height: "77%", backgroundColor: "transparent" }}
        onLoad={(editor) => editor.renderer.setPadding(20)}
        value={currentQuestion?.coding?.sources[0].code}
        onChange={onChangeHandler}
        // placeholder={`© xCodeClazz 2021 - ${new Date().getFullYear()}`}
        // debounceChangePeriod={1000}
        setOptions={{
          enableBasicAutocompletion: false,
          enableLiveAutocompletion: false,
          showLineNumbers: true,
          tabSize: 2,
        }}
      />
    </>
  );
};

export default CodingComponent;
