import _ from "lodash";
import { play } from "../../../../sfxs";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Haptics } from "@capacitor/haptics";
import { errorToast, postSubmissionAnyone } from "../../../../apis";
import { QuestionAttrs, SubmissionsResponse_SubmitAnyone } from "@com.xcodeclazz/monolithic-common";
import { updateCurrentAnyoneQuestionTimeline, updateCurrentAnyoneQuestionState, updateSolvedQuestionList, updateTopicScore } from "../../../../redux/reducers/questionState";
import {
  IonItem,
  IonList,
  IonRadio,
  IonButton,
  IonSpinner,
  IonButtons,
  IonRadioGroup,
  RadioGroupCustomEvent,
} from "@ionic/react";

const colorsMap = new Map<string, string>();
colorsMap.set("green", "success");
colorsMap.set("red", "danger");

const AnyoneComponent: React.FC<{
  currentQuestion: QuestionAttrs | undefined,
}> = ({ currentQuestion }) => {
  const dispatch = useDispatch();
  const isNotAllow = currentQuestion?.attempAllow! === 0;
  const [loading, setLoading] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>("");

  useEffect(() => {
    let f = _.find(currentQuestion?.anyone?.options, { checked: true });
    if (f) setSelected(f.body);
    else setSelected("");
  }, [currentQuestion]);

  const changeHandler = _.debounce((e: RadioGroupCustomEvent) => {
    const options = _.map(currentQuestion?.anyone?.options, (v, idx) => {
      let o = { ...v };
      o.checked = v.body === e.detail.value;
      setSelected(o.body);
      return o;
    });
    dispatch(updateCurrentAnyoneQuestionState({ options }));
  });

  const onSubmitHandler = () => {
    let answer = -1;
    _.forEach(currentQuestion?.anyone?.options, (el, idx) => { if (el.checked) answer = idx; });

    if (isNotAllow || answer < 0) return;
    setLoading(true);
    postSubmissionAnyone({ qid: currentQuestion?.id, answer }, response => {
      const { payload, points, timeline } = response?.data as SubmissionsResponse_SubmitAnyone;
      play(timeline.hasPassed, () => Haptics.vibrate({ duration: 1000 }));
      dispatch(updateTopicScore(points));
      dispatch(updateSolvedQuestionList(payload));
      dispatch(updateCurrentAnyoneQuestionTimeline(timeline));
      setLoading(false);
    }, error => {
      errorToast(error);
      setLoading(false);
    })
  };

  return (
    <>
      <IonList className="pb-52" lines="none">
        <IonRadioGroup allowEmptySelection={true} onIonChange={changeHandler} value={selected}>
          {_.map(currentQuestion?.anyone?.options, (el, idx) => (
            <IonItem key={idx}>
              <IonRadio 
                color={colorsMap.has(el?.color) ? colorsMap.get(el?.color) : "primary"}
                defaultChecked={el.checked}
                disabled={isNotAllow}
                value={el.body}
              ><pre className={`text-${el?.color}-500`} dangerouslySetInnerHTML={{ __html: el.body }} /></IonRadio>
            </IonItem>
          ))}
        </IonRadioGroup>
        {currentQuestion?.anyone?.options.length && <IonItem>
          <IonButtons slot="end">
            <IonButton fill="outline" disabled={isNotAllow} onClick={onSubmitHandler}>
              {loading && <IonSpinner name="dots" />} Submit
            </IonButton>
          </IonButtons>
        </IonItem>}
      </IonList>
    </>
  );
};

export default AnyoneComponent;
