import _ from "lodash";

import { menuOutline } from "ionicons/icons";
import { Resizer } from "../components/Resizer";
import Header from "../components/common/Header";
import React, { useEffect, useState } from "react";
import { getQueryParam } from "../utils/common/helper";
import { useDispatch, useSelector } from "react-redux";
import { errorToast, getQuestionState, getTopicQuestionsList, getTopicStatus } from "../apis";
import { clearQuestionState, saveCurrentQuestion, saveTopicDetails, saveTopicState } from "../redux/reducers/questionState";
import { IQuestionListElement, ITopic, ITopicStatus, QuestionsResponse_ShowQuestion } from "@com.xcodeclazz/monolithic-common";
import {
  IonCol,
  IonRow,
  IonGrid,
  IonIcon,
  IonMenu,
  IonPage,
  IonTitle,
  IonButton,
  IonFooter,
  IonHeader,
  IonButtons,
  IonContent,
  IonToolbar,
} from "@ionic/react";

import QuestionPanel from "../components/problems/QuestionPanel";
import AnswerPanel from "../components/problems/answer/AnswerPanel";
import DetailsPanel, { IDetailsPanel } from "../components/problems/details/DetailsPanel";
import { useHistory } from "react-router";

const screen = { width: 0, name: "sm" };
const Problems: React.FC<{}> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [cid] = useState(getQueryParam()["cid"]);
  const [tid] = useState(getQueryParam()["tid"]);

  const menuRef = React.createRef<HTMLIonMenuElement>();
  const detailsPanelRef = React.createRef<IDetailsPanel>();

  const topic_details: ITopic = useSelector((state: any) => state.questionState.topicDetails);
  const topic_state: ITopicStatus = useSelector((state: any) => state.questionState.topicState);
  const current_question: QuestionsResponse_ShowQuestion = useSelector((state: any) => state.questionState.currentQuestion);

  useEffect(() => { if (topic_state?.topic !== tid) { getTopicStatus({ cid, tid }, (r) => { dispatch(saveTopicState(r?.data)); }, errorToast); } });
  useEffect(() => { if (topic_details?.id !== tid) { getTopicQuestionsList({ cid, tid }, (r) => { if (r?.data?.topic?.isActive) dispatch(saveTopicDetails(r?.data)); else history.replace("/"); }, errorToast); } });
  useEffect(() => { return () => { dispatch(clearQuestionState()); } }, []);

  const onLoadQuestion = _.debounce((el: IQuestionListElement) => { 
    getQuestionState({ qid: el.question.toString() }, r => {
      dispatch(saveCurrentQuestion(r?.data));
      menuRef.current?.close();
    }, errorToast);
  });

  const breakPointTrigger = (width: number | undefined, name: string | undefined) => {
    screen.width = width as number;
    screen.name = name as string;
  };

  return (
    <React.Fragment>
      <Resizer onChange={breakPointTrigger} />
      <IonMenu contentId="problems-drawer" ref={menuRef}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Problems</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <QuestionPanel 
            currentQuestion={current_question?.question}
            onQuestionSelected={onLoadQuestion}
            topicDetails={topic_details} 
            topicState={topic_state} 
            paddingBottom={0}
          />
        </IonContent>
        <IonFooter>
          <IonToolbar>
            <IonTitle slot="end">xCodeClazz</IonTitle>
          </IonToolbar>
        </IonFooter>
      </IonMenu>
      <IonPage id="problems-drawer">
        <Header backBtn={`/courseboard?cid=${cid}`} />
        <IonContent>
          <IonGrid className="h-full">
            <IonRow className="h-full overflow-hidden">
              <IonCol sizeLg="4" sizeXl="3" className="hidden lg:block">
                <QuestionPanel 
                  currentQuestion={current_question?.question}
                  onQuestionSelected={onLoadQuestion}
                  topicDetails={topic_details} 
                  topicState={topic_state} 
                  paddingBottom={72}
                />
              </IonCol>
              <IonCol sizeMd="5" sizeLg="4" sizeXl="3" className="hidden md:block">
                <DetailsPanel ref={detailsPanelRef} currentQuestion={current_question?.question} />
              </IonCol>
              <IonCol size="12" sizeMd="7" sizeLg="4" sizeXl="6">
                <AnswerPanel
                  onTestClick={() => detailsPanelRef.current?.switchResult()}
                  currentQuestion={current_question?.question}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
        <IonFooter>
          <IonToolbar>
            <IonButtons className="lg:hidden">
              <IonButtons slot="start">
                <IonButton onClick={e => menuRef.current?.open()}><IonIcon icon={menuOutline} slot="icon-only" /></IonButton>
              </IonButtons>
            </IonButtons>
            <IonButtons slot="end">
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      </IonPage>
    </React.Fragment>
  );
};

export default Problems;
