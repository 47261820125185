import { configureStore } from "@reduxjs/toolkit";
import questionState from "./reducers/questionState";
import courseState from "./reducers/courseState";
import topicState from "./reducers/topicState";
import jokeState from "./reducers/jokeState";
import uiState from "./reducers/uiState";

export default configureStore({
  reducer: {
    uiState: uiState,
    jokeState: jokeState,
    topicState: topicState,
    courseState: courseState,
    questionState: questionState,
  },
});
