import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import {
  CoursesResponse_ShowCourses,
  CoursesResponse_EnrolledCourses,
} from "@com.xcodeclazz/monolithic-common";

export const courseState = createSlice({
  name: "courseState",
  initialState: {
    courses: { courses: [] } as CoursesResponse_ShowCourses,
    enrolledCourses: {
      active: undefined,
      courses: [],
    } as CoursesResponse_EnrolledCourses,
  },
  reducers: {
    saveCourses: (state, action) => {
      if (action.payload?.courses) state.courses = action.payload;
    },
    saveEnrolledCourses: (state, action) => {
      if (action.payload?.courses) state.enrolledCourses = action.payload;
    },
    clearEnrolledCourses: (state) => {
      state.enrolledCourses = { active: undefined, courses: [] };
    },
    clearCoursesState: (state) => {
      state.courses = { courses: [] };
      state.enrolledCourses = { active: undefined, courses: [] };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  saveCourses,
  clearCoursesState,
  saveEnrolledCourses,
  clearEnrolledCourses,
} = courseState.actions;

export default courseState.reducer;
