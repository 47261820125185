import _ from "lodash";
import { useState } from "react";
import { flaskOutline, paperPlaneOutline } from "ionicons/icons";
import { QuestionAttrs } from "@com.xcodeclazz/monolithic-common";
import {
  IonIcon,
  IonList,
  IonLabel,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";

import TestPanel from "./result/TestPanel";
import SubmitPanel from "./result/SubmitPanel";

enum RESULT_PANEL {
  TEST = "Test",
  SUBMIT = "Submit",
};

const ResultPanel: React.FC<{
  currentQuestion: QuestionAttrs | undefined,
}> = ({ currentQuestion }) => {
  const [currentTab, setCurrentTab] = useState<RESULT_PANEL>(RESULT_PANEL.TEST);
  return (
    <>
      <IonList lines="none" className="h-screen overflow-scroll pb-48">
        <IonSegment scrollable value={currentTab} onIonChange={e => setCurrentTab(e.detail.value as RESULT_PANEL)} mode="ios" className="rounded-none">
          <IonSegmentButton disabled={false} value={RESULT_PANEL.TEST} layout="icon-end">
            <IonLabel>{RESULT_PANEL.TEST}</IonLabel>
            <IonIcon icon={flaskOutline}></IonIcon>
          </IonSegmentButton>
          <IonSegmentButton disabled={false} value={RESULT_PANEL.SUBMIT} layout="icon-end">
            <IonLabel>{RESULT_PANEL.SUBMIT}</IonLabel>
            <IonIcon icon={paperPlaneOutline}></IonIcon>
          </IonSegmentButton>
        </IonSegment>
        {currentTab == RESULT_PANEL.TEST && <TestPanel currentQuestion={currentQuestion} />}
        {currentTab == RESULT_PANEL.SUBMIT && <SubmitPanel currentQuestion={currentQuestion} />}
      </IonList>
    </>
  );
};

export default ResultPanel;
