import _ from 'lodash';
import React from "react";
import {
  IonIcon,
  IonTitle,
  IonHeader,
  IonButton,
  IonButtons,
  IonToolbar,
  IonBackButton,
} from "@ionic/react";
import { useDispatch } from 'react-redux';
import { moonOutline } from 'ionicons/icons';
import { setTheme } from '../../redux/reducers/uiState';
import { isDark, toggleDark } from '../../utils/common/helper';
import { PREFERENCE_KEYS, setPreference } from '../../utils/common/cache';

const Header: React.FC<{
  backBtn?: string;
}> = (props) => {
  const dispatch = useDispatch();

  return (
    <IonHeader>
      <IonToolbar>
        {props.backBtn && <IonButtons slot="start" className='md:hidden'>
          <IonBackButton defaultHref={props.backBtn}></IonBackButton>
        </IonButtons>}
        <IonTitle>
          <strong className="text-yellow-500">x</strong>
          <strong className="cursor-pointer">CodeClazz</strong>
        </IonTitle>
        <IonButtons slot="end">
          <IonButton title="theme" onClick={e => { toggleDark(); setPreference(PREFERENCE_KEYS.DARK_MODE, `${isDark()}`); dispatch(setTheme({ theme: `${isDark()}` })) }}>
            <IonIcon slot="icon-only" icon={moonOutline} />
          </IonButton>
        </IonButtons>
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
