import _ from "lodash";
import moment from "moment";
import Header from "../components/common/Header";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { errorToast, getCoursesEnrolled } from "../apis";
import { calcuatePercentage } from "../utils/common/helper";
import { saveEnrolledCourses } from "../redux/reducers/courseState";
import { CoursesResponse_EnrolledCourses } from "@com.xcodeclazz/monolithic-common";
import {
  IonCol,
  IonRow,
  IonPage,
  IonText,
  IonCard,
  IonGrid,
  IonContent,
  IonSpinner,
  IonRefresher,
  IonCardTitle,
  IonCardHeader,
  IonCardContent,
  IonProgressBar,
  IonRippleEffect,
  IonRefresherContent,
  RefresherEventDetail,
} from "@ionic/react";

const Dashboard: React.FC<{}> = (props) => {
  const dispatch = useDispatch();
  const [isEventFetching, setEventFetching] = useState<boolean>(false);
  const [isCourseFetching, setCourseFetching] = useState<boolean>(false);
  const enrolled_courses_state: any = useSelector((state: any) => state.courseState.enrolledCourses);

  useEffect(() => {
    if (enrolled_courses_state?.courses?.length == 0) fetchSubscribedCourses();
  }, []);

  const handleRefresh = (event: CustomEvent<RefresherEventDetail>) => fetchSubscribedCourses(event);
  const fetchSubscribedCourses = (event?: CustomEvent<RefresherEventDetail>) => {
    setCourseFetching(true);
    getCoursesEnrolled((response) => {
        dispatch(saveEnrolledCourses(response?.data));
        event?.detail?.complete();
        setCourseFetching(false);
      }, (error) => {
        errorToast(error);
        event?.detail?.complete();
        setCourseFetching(false);
    });
  };

  return (
    <IonPage>
      <Header backBtn="/" />
      <IonContent fullscreen>
        <IonRefresher slot="fixed" pullFactor={0.5} pullMin={100} pullMax={200} onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <IonGrid>
          <IonRow>
            <IonCol className="p-4 flex flex-col space-y-2">
              <IonText className="text-4xl font-bold">My Courses</IonText>
              <IonText className="text-slate-400">You have not enrolled any course so far</IonText>
              {isCourseFetching && <><br /><IonSpinner name="dots" /></>}
            </IonCol>
          </IonRow>
          <IonRow>
            {
              _.map(enrolled_courses_state?.courses, (el, idx) => (
                <IonCol key={idx} size="12" sizeSm="6" sizeMd="5" sizeXl="4">
                  <IonCard className="ion-activatable rounded-xl" routerLink={`/courseboard?cid=${el?.id}`}>
                    <IonRippleEffect></IonRippleEffect>
                    <IonCardHeader>
                      <IonCardTitle>{el.status.solved} / {el.problems_count}</IonCardTitle>
                      <h4 className="font-bold" style={{ textTransform: "initial" }}>{el.title}</h4>
                      <small className="truncate" style={{ textTransform: "initial" }}>{el.subtitle}</small>
                    </IonCardHeader>
                    <IonCardContent>
                      <IonGrid>
                        <IonRow>
                          <IonCol size="4"><IonText>Started</IonText></IonCol>
                          <IonCol size="7"><IonText>{moment(el.validity.starts_ts).format("LL")}</IonText></IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size="4"><IonText>Ending</IonText></IonCol>
                          <IonCol size="7"><IonText>{moment(el.validity.ends_ts).format("LL")}</IonText></IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonCardContent>
                    <IonProgressBar value={calcuatePercentage(el.status.solved, el.problems_count) / 100} buffer={calcuatePercentage(el.status.solved, el.problems_count) / 100}/>
                  </IonCard>
                </IonCol>
              ))
            }
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Dashboard;
