import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import {
    ITopic,
    ITopicStatus,
    IMcqSubmissionTimeline,
    ICodingSubmissionTimeline,
    IAnyoneSubmissionTimeline,
    QuestionsResponse_ShowQuestion,
    SubmissionsResponse_ShowSubmissionsOfMcq,
    SubmissionsResponse_ShowSubmissionsOfAnyone,
    SubmissionsResponse_ShowSubmissionsOfCoding,
} from "@com.xcodeclazz/monolithic-common";

export const questionState = createSlice({
  name: "questionState",
  initialState: {
    topicDetails: undefined as ITopic | undefined,
    topicState: undefined as ITopicStatus | undefined,

    currentQuestionIdx: 0 as number,
    currentQuestion: undefined as QuestionsResponse_ShowQuestion | undefined,

    currentQuestionMcqSubmission: undefined as SubmissionsResponse_ShowSubmissionsOfMcq | undefined,
    currentQuestionAnyoneSubmission: undefined as SubmissionsResponse_ShowSubmissionsOfAnyone | undefined,
    currentQuestionCodingSubmission: undefined as SubmissionsResponse_ShowSubmissionsOfCoding | undefined,
  },
  reducers: {
    saveTopicState: (state, action) => { state.topicState = action.payload.state; },
    saveTopicDetails: (state, action) => { state.topicDetails = action.payload.topic; },
    saveCurrentQuestion: (state, action) => { state.currentQuestion = action.payload; },
    saveCurrentQuestionIdx: (state, action) => { state.currentQuestionIdx = Math.max(+action.payload.idx, 0); },

    updateTopicScore: (state, action) => {
        if (state.topicState) {
            state.topicState.score = action.payload.score;
            state.topicState.lastScore = action.payload.lastScore;
        }
    },
    updateSolvedQuestionList: (state, action) => {
        const payload = action.payload;
        const o = _.find(state.topicState?.solved, { question: payload?.attempResult?.question });
        if (o) {
            o.isCorrect = payload?.attempResult.isCorrect;
            o.timesSolved++;
        } else state.topicState?.solved.push(payload.attempResult);
        if (state.currentQuestion) state.currentQuestion.question.attempAllow = payload.attempAllow;
    },

    updateCurrentMcqQuestionState: (state, action) => {
        if (state.currentQuestion) state.currentQuestion.question.mcq!.options = action.payload.options;
    },
    updateCurrentAnyoneQuestionState: (state, action) => {
        if (state.currentQuestion) state.currentQuestion.question.anyone!.options = action.payload.options;
    },
    updateCurrentCodingQuestionState: (state, action) => {
        if (state.currentQuestion) {
            const src = _.find(state.currentQuestion.question.coding?.sources, { lang: action.payload.lang });
            if (src) src.code = action.payload.src;
        }
    },

    saveMcqSubmissions: (state, action) => { state.currentQuestionMcqSubmission = action.payload; },
    saveAnyoneSubmissions: (state, action) => { state.currentQuestionAnyoneSubmission = action.payload; },
    saveCodingSubmissions: (state, action) => { state.currentQuestionCodingSubmission = action.payload; },

    updateCurrentMcqQuestionTimeline: (state, action) => {
        const timeline: IMcqSubmissionTimeline = action.payload;
        if (state.currentQuestion) {
            state.currentQuestion.question.mcq!.options = timeline.options;
            state.currentQuestion.question.mcq!.solution = timeline.solution;
        }
        state.currentQuestionMcqSubmission?.submission?.timelines.unshift(timeline);
    },
    updateCurrentAnyoneQuestionTimeline: (state, action) => {
        const timeline: IAnyoneSubmissionTimeline = action.payload;
        if (state.currentQuestion) {
            state.currentQuestion.question.anyone!.options = timeline.options;
            state.currentQuestion.question.anyone!.solution = timeline.solution;
        }
        state.currentQuestionAnyoneSubmission?.submission?.timelines.unshift(timeline);
    },
    updateCurrentCodingQuestionTimeline: (state, action) => {
        const timeline: ICodingSubmissionTimeline = action.payload;
        const src = _.find(state.currentQuestion?.question.coding?.sources, { lang: timeline.lang });
        if (src) {
            src.code = timeline.src;
            src.lang = timeline.lang;
        }
        state.currentQuestionCodingSubmission?.submission?.timelines.unshift(timeline);
    },

    clearQuestionState: (state) => {
        state.currentQuestionIdx = 0;
        state.topicState = undefined;
        state.topicDetails = undefined;
        state.currentQuestion = undefined;
        state.currentQuestionMcqSubmission = undefined;
        state.currentQuestionAnyoneSubmission = undefined;
        state.currentQuestionCodingSubmission = undefined;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  saveTopicState,
  saveTopicDetails,
  saveCurrentQuestion,
  saveCurrentQuestionIdx,
  
  updateTopicScore,
  updateSolvedQuestionList,

  updateCurrentMcqQuestionState,
  updateCurrentAnyoneQuestionState,
  updateCurrentCodingQuestionState,

  saveMcqSubmissions,
  saveAnyoneSubmissions,
  saveCodingSubmissions,

  updateCurrentMcqQuestionTimeline,
  updateCurrentAnyoneQuestionTimeline,
  updateCurrentCodingQuestionTimeline,

  clearQuestionState,
} = questionState.actions;

export default questionState.reducer;