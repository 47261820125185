import _ from "lodash";
import { useState } from "react";
import { play } from '../../../../sfxs';
import { useDispatch } from "react-redux";
import { Haptics } from "@capacitor/haptics";
import { errorToast, postSubmissionMcq } from "../../../../apis";
import { QuestionAttrs, SubmissionsResponse_SubmitMcq } from "@com.xcodeclazz/monolithic-common";
import { updateCurrentMcqQuestionState, updateCurrentMcqQuestionTimeline, updateSolvedQuestionList, updateTopicScore } from "../../../../redux/reducers/questionState";
import {
  IonItem,
  IonList,
  IonButton,
  IonButtons,
  IonSpinner,
  IonCheckbox,
  CheckboxCustomEvent,
} from "@ionic/react";

const colorsMap = new Map<string, string>();
colorsMap.set("green", "success");
colorsMap.set("red", "danger");

const McqComponent: React.FC<{ 
  currentQuestion: QuestionAttrs | undefined 
}> = ({ currentQuestion }) => {
  const dispatch = useDispatch();
  const isNotAllow = currentQuestion?.attempAllow! === 0;
  const [loading, setLoading] = useState<boolean>(false);

  const changeHandler = _.debounce((e: CheckboxCustomEvent, index: number) => {
    const options = _.map(currentQuestion?.mcq?.options, (v, idx) => {
      let o = { ...v };
      if (v.body === e.detail.value && idx == index) o.checked = !o.checked;
      return o;
    });
    dispatch(updateCurrentMcqQuestionState({ options }));
  });

  const onSubmitHandler = () => {
    const indecies: number[] = [];
    _.forEach(currentQuestion?.mcq?.options, (el, idx) => { if (el.checked) indecies.push(idx); });

    if (isNotAllow) return;
    setLoading(true);
    postSubmissionMcq({ qid: currentQuestion?.id, selected: indecies }, response => {
      const { payload, points, timeline } = response?.data as SubmissionsResponse_SubmitMcq;
      play(timeline.hasPassed, () => Haptics.vibrate({ duration: 1000 }));
      dispatch(updateTopicScore(points));
      dispatch(updateSolvedQuestionList(payload));
      dispatch(updateCurrentMcqQuestionTimeline(timeline));
      setLoading(false);
    }, error => {
      errorToast(error);
      setLoading(false);
    })
  };

  return (
    <>
      <IonList className="pb-52" lines="none">
        {_.map(currentQuestion?.mcq?.options, (el, idx) => (
          <IonItem key={idx}>
            <IonCheckbox
              value={el.body}
              checked={el.checked}
              disabled={isNotAllow}
              onIonChange={e => changeHandler(e, idx)}
              color={colorsMap.has(el?.color) ? colorsMap.get(el?.color) : "primary"}
            ><pre className={`text-${el?.color}-500`} dangerouslySetInnerHTML={{ __html: el.body }} /></IonCheckbox>
          </IonItem>
        ))}
        {currentQuestion?.mcq?.options.length && <IonItem>
          <IonButtons slot="end">
            <IonButton fill="outline" disabled={isNotAllow} onClick={onSubmitHandler}>
              {loading && <IonSpinner name="dots" />} Submit
            </IonButton>
          </IonButtons>
        </IonItem>}
      </IonList>
    </>
  );
};

export default McqComponent;
