import _ from "lodash";
import moment from "moment";
import { play } from "../../../../sfxs";
import { Haptics } from "@capacitor/haptics";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBeforeDecimalPoint } from "../../../../utils/common/helper";
import { errorToast, getSubmissionsCoding, postSubmissionCoding } from "../../../../apis";
import { sendOutline, timeOutline, closeOutline, checkmarkOutline } from "ionicons/icons";
import { QuestionAttrs, SubmissionsResponse_ShowSubmissionsOfCoding, SubmissionsResponse_SubmitCoding } from "@com.xcodeclazz/monolithic-common";
import { saveCodingSubmissions, updateCurrentCodingQuestionTimeline, updateSolvedQuestionList, updateTopicScore } from "../../../../redux/reducers/questionState";
import {
  IonChip,
  IonIcon,
  IonItem,
  IonLabel,
  IonButton,
  IonSpinner,
  IonButtons,
  IonListHeader,
} from "@ionic/react";

const SubmitPanel: React.FC<{
  currentQuestion: QuestionAttrs | undefined,
}> = ({ currentQuestion }) => {
  const dispatch = useDispatch();
  const isNotAllow = currentQuestion?.attempAllow! === 0;
  const submissions_of_coding: SubmissionsResponse_ShowSubmissionsOfCoding = useSelector((state: any) => state.questionState.currentQuestionCodingSubmission);

  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    if (!submissions_of_coding?.submission) {
      getSubmissionsCoding({ qid: currentQuestion?.id }, (response) => dispatch(saveCodingSubmissions(response?.data)), errorToast);
    }
  }, []);

  const onSubmitHandler = () => {
    if (!currentQuestion?.coding) return;
    const source = _.find(currentQuestion.coding.sources, { lang: currentQuestion.language });
    if (isNotAllow) return;
    if (!source) return;

    setSubmitting(true);
    postSubmissionCoding({
      qid: currentQuestion.id,
      source: source
    }, response => {
      const { payload, points, timeline } = response?.data as SubmissionsResponse_SubmitCoding;
      play(timeline.hasPassed, () => Haptics.vibrate({ duration: 1000 }));
      dispatch(updateTopicScore(points));
      dispatch(updateSolvedQuestionList(payload));
      dispatch(updateCurrentCodingQuestionTimeline(timeline));
      setSubmitting(false);
    }, error => {
      errorToast(error);
      setSubmitting(false);
    });
  };

  return (
    <>
      {submissions_of_coding?.submission?.timelines.length == 0 && <IonItem><IonLabel>No Submissions Found</IonLabel></IonItem>}
      <IonItem>
        <IonButtons slot="start">
          <IonButton disabled={isNotAllow} onClick={onSubmitHandler}>
            {<IonIcon icon={sendOutline} slot="end" />}
            {isSubmitting ? <IonSpinner name="dots" /> : "Click to Submit"}
          </IonButton>
        </IonButtons>
      </IonItem>
      {_.map(submissions_of_coding?.submission?.timelines, (el, idx) => (
        <React.Fragment key={idx}>
          <IonListHeader lines="full">
            <IonIcon icon={timeOutline} color="primary" />
            <IonLabel className="ml-2 text-slate-500">{moment(el.createdAt).format("llll")}</IonLabel>
          </IonListHeader>
          {_.map(el.response, (el, idx) => (
            <IonItem key={idx} button>
              { el?.isSucceed ? <IonIcon icon={checkmarkOutline} color="success" slot="start" /> : <IonIcon icon={closeOutline} color="danger" slot="start" /> }
              <IonLabel>{ el?.test?.title }</IonLabel>
              <IonButton fill="clear" disabled>{getBeforeDecimalPoint(el?.expected_output?.result?.ms)}ms</IonButton>
            </IonItem>
          ))}
          <IonItem lines="full">
            <IonLabel>Language Used</IonLabel>
            <IonChip>{el.lang}</IonChip>
          </IonItem>
        </React.Fragment>
      ))}
    </>
  );
};

export default SubmitPanel;
