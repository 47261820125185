import _ from "lodash";
import { useEffect } from "react";
import { IQuestionListElement, ITopic, ITopicStatus, QuestionAttrs } from "@com.xcodeclazz/monolithic-common";
import { checkmarkDoneOutline, chevronForwardOutline, closeOutline, trendingUpOutline } from "ionicons/icons";
import {
  IonRow,
  IonCol,
  IonGrid,
  IonCard,
  IonIcon,
  IonList,
  IonItem,
  IonLabel,
  IonBadge,
  IonButton,
  IonCardTitle,
  IonCardHeader,
  IonCardContent,
  IonCardSubtitle,
} from "@ionic/react";

const QuestionPanel: React.FC<{ 
  paddingBottom: number,
  topicDetails: ITopic | undefined,
  topicState: ITopicStatus | undefined,
  currentQuestion: QuestionAttrs | undefined,
  onQuestionSelected: (el: IQuestionListElement) => void,
}> = ({ topicState, topicDetails, currentQuestion, paddingBottom, onQuestionSelected }) => {

  const isSolved = (question: string) => {
    const found = topicState?.solved.find(e => e?.question?.toString() == question);
    return found ? (found.isCorrect ? 1 : 0) : -1;
  };

  useEffect(_.debounce(() => {
    if (!currentQuestion) {
      const q = _.first(topicDetails?.questions);
      if (q) onQuestionSelected({ question: (q.question as QuestionAttrs)?.id, index: q.index });
    }
  }));

  return (
    <>
      {!topicState && <IonCard className="h-full ion-no-margin animate-pulse" />}
      {topicState  && <>
        <IonCard className="ion-no-margin">
          <IonCardHeader>
            <IonCardTitle>{topicDetails?.title}</IonCardTitle>
            <IonCardSubtitle className="truncate">{topicDetails?.subtitle}</IonCardSubtitle>
          </IonCardHeader>
          <IonCardContent>
            <IonGrid>
              <IonRow>
                <IonCol size="8" sizeSm="5" className="space-x-2">
                  <IonBadge title="Last Score" color={topicState?.lastScore.color == 'red' ? "danger": 'success'}>{topicState?.lastScore.value}</IonBadge>
                  <IonBadge title="Score" color={topicState?.score.color == 'red' ? "danger": 'success'}>{topicState?.score.value}</IonBadge>
                </IonCol>
                <IonCol size="4" sizeSm="7" className="space-x-2 flex items-center justify-end">
                  <IonLabel color="success" className="truncate">Keep Up</IonLabel>
                  <IonBadge color="success"><IonIcon icon={trendingUpOutline} /></IonBadge>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardContent>
        </IonCard>
        <IonCard className="mx-0 h-screen overflow-scroll">
          <IonList className={`h-screen overflow-scroll pb-${paddingBottom}`} lines="none">
            {(topicDetails?.questions.length || 0) == 0 && <IonItem><IonLabel>No Questions...</IonLabel></IonItem>}
            {_.map(topicDetails?.questions, (el, idx) => (
              <IonItem key={idx} button title={(el.question as QuestionAttrs)?.id} className="ion-border" onClick={e => onQuestionSelected({ question: (el.question as QuestionAttrs).id, index: el.index })}>
                {currentQuestion?.id === (el.question as QuestionAttrs)?.id.toString() && <IonButton fill="clear"><IonIcon slot="icon-only" icon={chevronForwardOutline} /></IonButton>}
                <IonLabel color={currentQuestion?.id === (el.question as QuestionAttrs)?.id.toString() ? "primary" : ""}>{(el.question as QuestionAttrs)?.title}</IonLabel>
                {isSolved((el.question as QuestionAttrs)?.id) == 0 && <IonButton color="danger" fill="clear"><IonIcon slot="icon-only" icon={closeOutline} /></IonButton>}
                {isSolved((el.question as QuestionAttrs)?.id) == 1 && <IonButton color="success" fill="clear"><IonIcon slot="icon-only" icon={checkmarkDoneOutline} /></IonButton>}
              </IonItem>
            ))}
          </IonList>
        </IonCard>
      </>}
    </>
  );
};

export default QuestionPanel;
