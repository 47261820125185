import _ from "lodash";
import Header from "../components/common/Header";
import { lockClosedOutline, lockOpenOutline } from "ionicons/icons";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getQueryParam } from "../utils/common/helper";
import { calcuatePercentage } from "../utils/common/helper";
import { errorToast, postCourseEnroll } from "../apis/index";
import { saveEnrolledCourseTopics } from "../redux/reducers/topicState";
import { CoursesResponse_EnrolledCourse } from "@com.xcodeclazz/monolithic-common";
import {
  IonRow,
  IonCol,
  IonGrid,
  IonText,
  IonChip,
  IonCard,
  IonPage,
  IonIcon,
  IonButton,
  IonButtons,
  IonContent,
  IonSpinner,
  IonRefresher,
  IonCardTitle,
  IonCardHeader,
  IonProgressBar,
  IonCardContent,
  IonRippleEffect,
  IonCardSubtitle,
  IonRefresherContent,
  RefresherEventDetail,
} from "@ionic/react";

const CourseBoard: React.FC<{}> = (props) => {
  const dispatch = useDispatch();
  const [cid] = useState(getQueryParam()["cid"]);
  const [isTopicsFetching, setTopicsFetching] = useState<boolean>(false);
  const enrolled_course_topics_state: { [cid: string] : CoursesResponse_EnrolledCourse} = useSelector((state: any) => state.topicState.enrolledCourseTopics);

  useEffect(() => { 
    if (!enrolled_course_topics_state[cid]) fetchCourseDetails();
  }, [enrolled_course_topics_state]);

  const handleRefresh = (event: CustomEvent<RefresherEventDetail>) => fetchCourseDetails(event);
  const fetchCourseDetails = (event?: CustomEvent<RefresherEventDetail>) => {
    setTopicsFetching(true);
    postCourseEnroll({ cid }, (response) => {
      dispatch(saveEnrolledCourseTopics({cid, data: response?.data}));
      event?.detail?.complete();
      setTopicsFetching(false);
    }, (error) => {
      errorToast(error);
      event?.detail?.complete();
      setTopicsFetching(false);
    });
  };

  return (
    <IonPage>
      <Header backBtn="/dashboard" />
      <IonContent fullscreen>
        <IonRefresher slot="fixed" pullFactor={0.5} pullMin={100} pullMax={200} onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <IonGrid>
          <IonRow>
            <IonCol className="p-4 flex flex-col space-y-2">
              <IonText className="text-4xl font-bold">Topics</IonText>
              <IonText className="text-slate-400">Additional topics will unlock one by one as we progress further.</IonText>
              {isTopicsFetching && <><br /><IonSpinner name="dots" /></>}
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonGrid>
          <IonRow>
            {_.map(enrolled_course_topics_state[cid]?.topics, (el, idx: number) => (
              <IonCol key={idx} size="12" sizeSm="6" sizeMd="6" sizeLg="5" sizeXl="4">
                <IonCard disabled={!el.topic.isActive} className="ion-activatable rounded-lg" routerLink={`/problems?cid=${cid}&tid=${el.topic.id}`}>
                    <IonRippleEffect></IonRippleEffect>
                    <IonCardHeader>
                      <section className="flex flex-row justify-between items-center">
                        <IonCardTitle>{el.topic.title}</IonCardTitle>
                        <IonButtons>
                          <IonButton>
                            <IonIcon slot="icon-only" icon={el?.topic.isActive ? lockOpenOutline : lockClosedOutline} />
                          </IonButton>
                        </IonButtons>
                      </section>
                      <IonCardSubtitle className="truncate">{el.topic.subtitle}</IonCardSubtitle>
                    </IonCardHeader>
                    <IonCardContent>
                      <IonGrid>
                        <IonRow>
                          <IonCol size="5"><IonText>Questions</IonText></IonCol>
                          <IonCol><IonText className="font-bold">{el.state.problems_count}</IonText></IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size="5"><IonText>Level</IonText></IonCol>
                          <IonCol><IonText className="font-bold">{el.topic.level}</IonText></IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size="5"><IonText>Completed</IonText></IonCol>
                          <IonCol className="flex flex-col justify-center"><IonProgressBar value={calcuatePercentage(el.state.solved, el.state.problems_count) / 100}/></IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size="12">{_.map(el.topic.eligibility, (e, i) => (<IonChip className="capitalize" key={i} outline>{e}</IonChip>))}</IonCol>
                        </IonRow>
                      </IonGrid>
                    </IonCardContent>
                  </IonCard>
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default CourseBoard;
