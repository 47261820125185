import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";

export interface IJokeResponse {
  id: number;
  type: string;
  setup: string;
  punchline: string;
}

export const jokeState = createSlice({
  name: "jokeState",
  initialState: {
    joke: undefined as IJokeResponse | undefined,
  },
  reducers: {
    saveJoke: (state, action) => {
      state.joke = action.payload.joke;
    },
  },
});

// Action creators are generated for each case reducer function
export const { saveJoke } = jokeState.actions;

export default jokeState.reducer;
