import _ from "lodash";
import React from "react";
import AceEditor from "react-ace";
import { QuestionAttrs } from "@com.xcodeclazz/monolithic-common";
import {
  IonCol,
  IonRow,
  IonChip,
  IonGrid,
  IonIcon,
  IonItem,
  IonList,
  IonLabel,
  IonButton,
  IonButtons,
} from "@ionic/react";
import {
  starOutline,
  flashOutline,
  stopwatchOutline,
} from "ionicons/icons";

const getLineCodeLinesHeight = (code: string) =>
  code ? `${code.split("\n").length * 5}%` : `${5}%`;

const ProblemPanel: React.FC<{
  currentQuestion: QuestionAttrs | undefined,
}> = ({ currentQuestion }) => {
  return (
    <>
      <IonList lines="none" className="h-screen overflow-scroll pb-48">

        <IonItem>
          <IonGrid>
            <IonRow>
              <IonCol size="4">
                <IonButton disabled expand="block" fill="clear">
                  <IonIcon size="small" slot="start" icon={stopwatchOutline} />{currentQuestion?.attempAllow}
                </IonButton>
              </IonCol>
              <IonCol size="4">
                <IonButton disabled expand="block" fill="clear">
                  <IonIcon size="small" slot="start" icon={flashOutline} />{currentQuestion?.panelty}
                </IonButton>
              </IonCol>
              <IonCol size="4">
                <IonButton disabled expand="block" fill="clear">
                  <IonIcon size="small" slot="start" icon={starOutline} />{currentQuestion?.points}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>

        <IonItem>
          <IonLabel>{currentQuestion?.title}</IonLabel>
          <IonButtons></IonButtons>
        </IonItem>

        <IonItem className="text-sm text-slate-400">
          <p dangerouslySetInnerHTML={{ __html: currentQuestion?.body.text || '' }}/>
        </IonItem>

        {currentQuestion?.body.code && <AceEditor
          width="100"
          fontSize={15}
          mode={"python"}
          showGutter={false}
          wrapEnabled={false}
          highlightActiveLine={false}
          name={`ID-${Math.random()}`}
          theme={`tomorrow_night_bright`}
          editorProps={{ $blockScrolling: true }}
          onLoad={(editor) => editor.renderer.setPadding(15)}
          style={{
            height: getLineCodeLinesHeight(currentQuestion?.body.code || ''),
            backgroundColor: "transparent",
          }}
          value={currentQuestion?.body.code}
          setOptions={{
            enableBasicAutocompletion: false,
            enableLiveAutocompletion: false,
            showLineNumbers: true,
            readOnly: true,
            tabSize: 2,
            wrap: true,
          }}
        />}

        {_.map(currentQuestion?.coding?.examples, (el, idx) => (
          <React.Fragment key={idx}>
            <IonItem className="text-sm"><IonLabel>Example: {idx + 1}</IonLabel></IonItem>
            <IonItem><pre dangerouslySetInnerHTML={{ __html: el.body }}/></IonItem>
          </React.Fragment>
        ))}

        {_.map(currentQuestion?.coding?.io, (el, idx) => (
          <React.Fragment key={idx}>
            <IonItem className="text-sm"><IonLabel>Sample Input: {idx + 1}</IonLabel></IonItem>
            <IonItem><pre dangerouslySetInnerHTML={{ __html: el.input }} /></IonItem>
            <IonItem className="text-sm"><IonLabel>Sample Output: {idx + 1}</IonLabel></IonItem>
            <IonItem><pre dangerouslySetInnerHTML={{ __html: el.output }} /></IonItem>
          </React.Fragment>
        ))}

        {(currentQuestion?.coding?.constraints?.length || 0) > 0 && <>
          <IonItem className="text-sm"><IonLabel>Constraints:</IonLabel></IonItem>
          {_.map(currentQuestion?.coding?.constraints, (el, idx) => (
            <IonItem><pre dangerouslySetInnerHTML={{ __html: el.body }} /></IonItem>
          ))}
        </>}

        <IonItem>
          <IonLabel>Views</IonLabel>
          <IonChip>{currentQuestion?.views}</IonChip>
        </IonItem>
        <IonItem>
          <IonLabel>Solved</IonLabel>
          <IonChip>{currentQuestion?.solved}</IonChip>
        </IonItem>
      </IonList>
    </>
  );
};

export default ProblemPanel;
