import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import { CoursesResponse_EnrolledCourse } from "@com.xcodeclazz/monolithic-common";

export const topicState = createSlice({
  name: "topicState",
  initialState: {
    enrolledCourseTopics: {} as {
      [cid: string]: CoursesResponse_EnrolledCourse;
    },
  },
  reducers: {
    saveEnrolledCourseTopics: (state, action) => {
      if (action.payload?.cid && action.payload?.data) {
        state.enrolledCourseTopics[action.payload?.cid] = action.payload?.data;
      }
    },
    clearTopicState: (state) => {
      state.enrolledCourseTopics = {};
    },
  },
});

// Action creators are generated for each case reducer function
export const { clearTopicState, saveEnrolledCourseTopics } = topicState.actions;

export default topicState.reducer;
