import _ from "lodash";

import ResultPanel from "./ResultPanel";
import ProblemPanel from "./ProblemPanel";

import { helpOutline, tvOutline } from "ionicons/icons";
import { QuestionAttrs } from "@com.xcodeclazz/monolithic-common";
import { forwardRef, useImperativeHandle, useEffect, useState } from "react";
import { IQuestionType } from "@com.xcodeclazz/monolithic-common/build/constants/questions";
import {
  IonIcon,
  IonCard,
  IonLabel,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";

enum DETAILS_PANEL {
  PROBLEM = "Problem",
  RESULT = "Result",
};

export interface IDetailsPanel {
  switchProblem(): void;
  switchResult(): void;
}

const DetailsPanel = forwardRef<IDetailsPanel, {
  currentQuestion: QuestionAttrs | undefined,
}>((props, ref) => {
  const [currentTab, setCurrentTab] = useState<DETAILS_PANEL>(DETAILS_PANEL.PROBLEM);

  useImperativeHandle(ref, () => ({
    switchProblem: () => setCurrentTab(DETAILS_PANEL.PROBLEM),
    switchResult: () => setCurrentTab(DETAILS_PANEL.RESULT),
  }));

  useEffect(() => setCurrentTab(DETAILS_PANEL.PROBLEM), [props.currentQuestion?.type]);

  const getCurrentComponent = () => {
    if (currentTab == DETAILS_PANEL.PROBLEM) return <ProblemPanel currentQuestion={props.currentQuestion} />;
    else if (currentTab == DETAILS_PANEL.RESULT) return <ResultPanel currentQuestion={props.currentQuestion} />;
  };

  return <>
    {!props.currentQuestion && <IonCard className="h-full ion-no-margin animate-pulse" />}
    {props.currentQuestion  && <IonCard className="h-full ion-no-margin">
      <IonSegment scrollable value={currentTab} onIonChange={e => setCurrentTab(e.detail.value as DETAILS_PANEL)}>
        <IonSegmentButton value={DETAILS_PANEL.PROBLEM} layout="icon-end">
          <IonLabel>{DETAILS_PANEL.PROBLEM}</IonLabel>
          <IonIcon icon={helpOutline}></IonIcon>
        </IonSegmentButton>
        {props.currentQuestion?.type === IQuestionType.Coding && 
        <IonSegmentButton value={DETAILS_PANEL.RESULT} layout="icon-end">
          <IonLabel>{DETAILS_PANEL.RESULT}</IonLabel>
          <IonIcon icon={tvOutline}></IonIcon>
        </IonSegmentButton>}
      </IonSegment>
      {getCurrentComponent()}
    </IonCard>}
  </>;

});

export default DetailsPanel;
