import _ from "lodash";
import React from "react";
import { IonContent, IonPage } from "@ionic/react";

import "./Auth.css";

const Auth: React.FC<{}> = (props) => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="bg-image"></div>
        <div className="absolute top-[25%] left-[10%] z-20">
          <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold">Un<span className="text-yellow-500">auth</span>orized</h1>
          <p>401, Please login/register to get inside.</p>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Auth;
