import _ from "lodash";
import { useEffect, useState } from "react";
import { QuestionAttrs } from "@com.xcodeclazz/monolithic-common";
import { IQuestionType } from "@com.xcodeclazz/monolithic-common/build/constants/questions";
import { tvOutline, codeOutline, discOutline, helpOutline, stopOutline } from "ionicons/icons";
import { IonCol, IonRow, IonCard, IonGrid, IonIcon, IonSegment, IonSegmentButton } from "@ionic/react";

import McqComponent from "./mcq/McqComponent";
import ResultPanel from "../details/ResultPanel";
import ProblemPanel from "../details/ProblemPanel";
import AnyoneComponent from "./anyone/AnyoneComponent";
import CodingComponent from "./coding/CodingComponent";

enum ANSWER_PANEL {
  PROBLEM = "Problem",
  RESULT = "Result",
  CODING = "Coding",
  ANYONE = "Anyone",
  EMPTY = "Empty",
  MCQ = "Mcq",
};

const AnswerPanel: React.FC<{
  currentQuestion: QuestionAttrs | undefined,
  onTestClick: () => void;
}> = ({ currentQuestion, onTestClick }) => {

  const [currentTab, setCurrentTab] = useState<ANSWER_PANEL>(ANSWER_PANEL.EMPTY);

  const getCurrentComponent = () => {
    if (currentTab == ANSWER_PANEL.PROBLEM) return <ProblemPanel currentQuestion={currentQuestion} />;
    else if (currentTab == ANSWER_PANEL.MCQ) return <McqComponent currentQuestion={currentQuestion} />;
    else if (currentTab == ANSWER_PANEL.RESULT) return <ResultPanel currentQuestion={currentQuestion} />;
    else if (currentTab == ANSWER_PANEL.ANYONE) return <AnyoneComponent currentQuestion={currentQuestion} />;
    else if (currentTab == ANSWER_PANEL.CODING) return <CodingComponent currentQuestion={currentQuestion} onTestClick={onTestClick} />;
  };

  useEffect(() => {
    if (currentQuestion?.type === IQuestionType.Mcq) setCurrentTab(ANSWER_PANEL.MCQ);
    else if (currentQuestion?.type === IQuestionType.Anyone) setCurrentTab(ANSWER_PANEL.ANYONE);
    else if (currentQuestion?.type === IQuestionType.Coding) setCurrentTab(ANSWER_PANEL.CODING);
    else setCurrentTab(ANSWER_PANEL.PROBLEM);
  }, [currentQuestion?.type]);

  return (
    <>
      {!currentQuestion && <IonCard className="h-full ion-no-margin animate-pulse" />}
      {currentQuestion  && <IonCard className="h-full ion-no-margin">
        <IonGrid className="ion-no-padding">
          <IonRow>
            <IonCol>
              <IonSegment scrollable value={currentTab} onIonChange={e => setCurrentTab(e.detail.value as ANSWER_PANEL)}>
                <IonSegmentButton disabled={false} className="md:hidden" value={ANSWER_PANEL.PROBLEM} layout="icon-end">
                  <IonIcon icon={helpOutline} />
                </IonSegmentButton>
                {currentQuestion?.type === IQuestionType.Coding && 
                <IonSegmentButton disabled={false} className="md:hidden" value={ANSWER_PANEL.RESULT} layout="icon-end" >
                  <IonIcon icon={tvOutline} />
                </IonSegmentButton>}
                <IonSegmentButton disabled={currentQuestion?.type !== IQuestionType.Coding} className="md:hidden" value={ANSWER_PANEL.CODING} layout="icon-end">
                  <IonIcon icon={codeOutline} />
                </IonSegmentButton>
                <IonSegmentButton disabled={currentQuestion?.type !== IQuestionType.Anyone} className="md:hidden" value={ANSWER_PANEL.ANYONE} layout="icon-end">
                  <IonIcon icon={discOutline} />
                </IonSegmentButton>
                <IonSegmentButton disabled={currentQuestion?.type !== IQuestionType.Mcq} className="md:hidden" value={ANSWER_PANEL.MCQ} layout="icon-end">
                  <IonIcon icon={stopOutline} />
                </IonSegmentButton>
              </IonSegment>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="h-screen overflow-scroll">{getCurrentComponent()}</IonCol>
          </IonRow>
        </IonGrid>
      </IonCard>}
    </>
  );
};

export default AnswerPanel;
