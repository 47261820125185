import _ from "lodash";
import { diffSentences } from "diff";
import React, { useState } from "react";
import { checkmarkDoneOutline, playOutline } from "ionicons/icons";
import { errorToast, postSubmissionExecuteSolutionCode } from "../../../../apis";
import { QuestionAttrs, SubmissionsResponse_ExecuteSolutionCode } from "@com.xcodeclazz/monolithic-common";
import {
  IonCol,
  IonRow,
  IonChip,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonButton,
  IonSpinner,
  IonButtons,
  IonTextarea,
} from "@ionic/react";

const TestPanel: React.FC<{
  currentQuestion: QuestionAttrs | undefined,
}> = ({ currentQuestion }) => {
  const inputRef = React.createRef<HTMLIonTextareaElement>();
  const [isTestRunning, setTestRunning] = useState<boolean>(false);
  const [excuteSolutionResponse, setExcuteSolutionResponse] = useState<SubmissionsResponse_ExecuteSolutionCode>();

  const runCode = () => {
    if (!currentQuestion?.coding) return;

    const source = _.find(currentQuestion.coding.sources, { lang: currentQuestion.language });
    if (!source) return;

    setTestRunning(true);
    postSubmissionExecuteSolutionCode({
      source: source,
      qid: currentQuestion.id,
      input: inputRef?.current?.value?.toString() || '',
    }, response => {
      setExcuteSolutionResponse(response?.data);
      setTestRunning(false);
    }, error => {
      errorToast(error);
      setTestRunning(false);
    });
  };

  const getBothStdouts = () => {
    return {
      actual: excuteSolutionResponse?.actual_output.result.executionResult.stdout,
      expected: excuteSolutionResponse?.expected_output.result.executionResult.stdout
    }
  };

  const getDifference = (answer: string = "", solution: string = "") => {
    let span = null;
    let fragment: string[] = [];
    diffSentences(answer, solution).forEach((part) => {
      const color = part.added ? "green" : part.removed ? "red" : "grey";
      span = document.createElement("span");
      span.style.color = color;
      span.appendChild(document.createTextNode(part.value));
      fragment.push(span.outerHTML);
    });
    return fragment.join("\n\n");
  };

  return (
    <>
      <IonItem>
        <IonLabel>Sample Test Cases</IonLabel>
        <IonButtons slot="end">
          <IonButton>
            <IonIcon icon={playOutline} slot="icon-only" />
          </IonButton>
        </IonButtons>
      </IonItem>

      <IonItem><IonLabel>Please click on play button above to run the cases</IonLabel></IonItem>

      {_.map(new Array(0), (el, idx) => (
        <IonItem key={idx} button>
          <IonIcon icon={checkmarkDoneOutline} color="success" slot="start" />
          <IonLabel>Title {idx}</IonLabel>
          <IonButton fill="clear" color="success" disabled>
            {123 * idx}ms
          </IonButton>
        </IonItem>
      ))}

      <IonItem>
        <IonLabel>Custom Test Cases</IonLabel>
        <IonButtons slot="end">
          <IonButton onClick={runCode}>
            <IonIcon icon={playOutline} slot="icon-only" />
          </IonButton>
        </IonButtons>
      </IonItem>

      <IonGrid>
        <IonRow>
          <IonCol size="12">
            <IonTextarea
              rows={4}
              cols={100}
              ref={inputRef}
              fill="outline"
              placeholder="Type your input here please..."
            />
          </IonCol>
        </IonRow>
        {isTestRunning && <IonRow><IonCol><IonSpinner name="dots" /></IonCol></IonRow>}
      </IonGrid>

      {excuteSolutionResponse && 
      <IonItem>
        <IonLabel>Result</IonLabel> { getBothStdouts().actual == getBothStdouts().expected ? <IonChip color="success">Pass</IonChip> : <IonChip color="danger">Failed</IonChip> }
      </IonItem>}

      <IonItem>
        <pre dangerouslySetInnerHTML={{ __html: getDifference(getBothStdouts().actual, getBothStdouts().expected) }}/>
      </IonItem>
    </>
  );
};

export default TestPanel;
